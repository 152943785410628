import { SalaryService } from '@/services/salaryService';
import { formatAccountNumber } from '@/utils/accountingHelper';

const getDefaultState = () => ({
  salaryTypes: [],
  pensionProviders: [],
  PayrollSettings: null,
});

const state = getDefaultState();

const getters = {
  getSalaryTypes(state) {
    return state.salaryTypes.map((salaryType) => {
      const searchboxTitle = `${salaryType.number} ${salaryType.name}`;
      const listSearchTitle = `${salaryType.number} ${salaryType.name}`.toLowerCase();
      const salaryTypeFullNumber = formatAccountNumber(salaryType);

      return {
        ...salaryType,
        searchboxTitle,
        listSearchTitle,
        salaryTypeFullNumber,
        originalName: salaryType.name,
      };
    });
  },
};

const actions = {
  async fetchHolidayTemplates() {
    return SalaryService.getHolidayTemplates();
  },
  async fetchSalaryTypeTemplatesByOccupationCode(_, { companyId, number, ignoreCache }) {
    return SalaryService.getSalaryTypeTemplatesByOccupationCode(companyId, number, ignoreCache);
  },
  async updateSalaryTypeTemplateByOccupationCode(_, { companyId, occupationCode, data }) {
    return SalaryService.updateSalaryTypeTemplateByOccupationCode(companyId, occupationCode, data);
  },
  async fetchSalaryTypes({ commit }, { companyId }) {
    const salaryTypes = await SalaryService.getSalaryTypes(companyId);
    commit('setSalaryTypes', salaryTypes);
  },
  async getSalaryTypes({ commit }, { companyId }) {
    return SalaryService.getSalaryTypes(companyId);
  },
  async getPensionProviders({ commit }) {
    const pensionProviders = await SalaryService.getPensionProviders();
    commit('setPensionProviders', pensionProviders);
  },
  async getPayrollSettings({ commit }, { companyId }) {
    const payrollSettings = await SalaryService.getPayrollSettings(companyId);
    commit('setPayrollSetting', payrollSettings);
  },
  async createSalaryType({ commit }, { companyId, data }) {
    const salaryType = await SalaryService.createSalaryType(companyId, data);
    commit('addSalaryTypes', salaryType);
    return salaryType;
  },
  async updateSalaryType({ commit }, { companyId, data, id }) {
    const updatedSalaryType = await SalaryService.updateSalaryType(companyId, data, id);
    commit('updateSalaryTypes', updatedSalaryType);
    return updatedSalaryType;
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setSalaryTypes(state, salaryTypes) {
    state.salaryTypes = salaryTypes;
  },
  addSalaryTypes(state, salaryTypes) {
    state.salaryTypes.unshift(salaryTypes);
  },
  updateSalaryTypes(state, salaryType) {
    state.salaryTypes = state.salaryTypes.map((item) => (item.id === salaryType.id ? salaryType : item));
  },
  setPensionProviders(state, payload) {
    state.pensionProviders = payload;
  },
  setPayrollSetting(state, payload) {
    state.PayrollSettings = payload;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
