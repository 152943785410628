import api from '@/utils/api';
import { getContactWarning } from '@/utils/contactHelper';
import type {
  ContactResponse,
  ContactFindBody,
  ContactUpdateBody,
  ContactCreateBody,
  ContactNumberSuggestionResponse,
  GetContactsQuery,
  PaginationQueryRequest,
} from '@/types/schema';
import type { PaginatedResponse } from '@/utils/pagination';

function extendContact(contact: ContactResponse) {
  return {
    ...contact,
    warning: getContactWarning(contact),
  };
}

function mapContactResponse(response: any): any {
  if (Array.isArray(response)) {
    const extended = response.map(extendContact);
    return extended;
  } else {
    return extendContact(response);
  }
}

export const CompanyContactService = {
  getContacts(companyId: string, ignoreCache: boolean): any {
    return api({
      method: 'get',
      url: `companies/${companyId}/contacts`,
      ignoreCache,
    }).then(mapContactResponse);
  },
  getPaginatedContacts(
    companyId: string,
    filters: GetContactsQuery & PaginationQueryRequest,
  ): Promise<PaginatedResponse<ContactResponse>> {
    return api({
      method: 'get',
      url: `/v2/companies/${companyId}/contacts`,
      params: {
        ...filters,
      },
      abortKey: 'getContactsV2',
    });
  },
  getContact(companyId: string, contactId: string) {
    return api({
      method: 'get',
      url: `companies/${companyId}/contacts/${contactId}`,
      ignoreCache: true,
    }).then(mapContactResponse);
  },
  createContact(companyId: string, data: ContactCreateBody): any {
    return api({
      method: 'post',
      url: `companies/${companyId}/contacts`,
      data,
    }).then(mapContactResponse);
  },
  updateContact(companyId: string, contactId: string, data: ContactUpdateBody): any {
    return api({
      method: 'patch',
      url: `companies/${companyId}/contacts/${contactId}`,
      data,
    }).then(mapContactResponse);
  },
  deleteContact(companyId: string, contactId: string) {
    return api({
      method: 'delete',
      url: `companies/${companyId}/contacts/${contactId}`,
    });
  },
  findContacts(companyId: string, data: ContactFindBody) {
    return api({
      method: 'post',
      abortKey: 'findContacts',
      url: `companies/${companyId}/contacts/find`,
      data,
    });
  },
  getContactNumberSuggestion(companyId: string): Promise<ContactNumberSuggestionResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/contacts/account_number_suggestions`,
    });
  },
};
