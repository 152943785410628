import api from '@/utils/api';
import type { VoucherReportQuery, VoucherReportResponse } from '@/types/schema';

export const VoucherService = {
  getVouchers(companyId: string, filters: VoucherReportQuery): Promise<VoucherReportResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vouchers`,
      params: filters,
    });
  },
};
