<template>
  <v-data-table
    :items-per-page="-1"
    :headers="headers"
    :items="items"
    :loading="loading"
    sort-by="deletedAtSort"
    sort-desc
    :custom-sort="numbersAlphabeticSort"
    item-key="id"
    show-select
    checkbox-color="primary"
    :item-class="isSelected"
    no-data-text="Ingen flere godkjente filer"
    hide-default-footer
    :value="selection"
    v-bind="$attrs"
    @input="$emit('change:selection', $event)"
    @click:row="rowClicked"
  >
    <template #item.preview="{ item }">
      <v-btn v-if="item.fileId" icon @click.stop="$emit('openPDFPreview', item)">
        <v-icon small>mdi-eye</v-icon>
      </v-btn>
    </template>
    <template #item.uploadedAtSort="{ item }">
      <div>{{ formatDate(item.uploadedAt) }}</div>
    </template>
    <template #item.deletedAtSort="{ item }">
      <div>{{ formatDate(item.deletedAt) }}</div>
    </template>
    <template #item.actions="{ item }">
      <v-btn outlined small color="primary" @click.stop="$emit('restore', { item })">Gjenopprett</v-btn>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type { DataTableHeader } from 'vuetify';
import type { InboxFile } from '../accounting/models/InboxFile';
import { numbersAlphabeticSort, formatDate } from '@/utils/shared';

export default defineComponent({
  model: {
    prop: 'selection',
    event: 'change:selection',
  },
  props: {
    items: {
      type: Array as PropType<InboxFile[]>,
      default: () => [],
    },
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      required: true,
    },
    rowClicked: {
      type: Function,
      default: () => {},
    },
    selection: {
      type: Array as PropType<InboxFile[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    highlightSelected: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['restore', 'change:selection', 'openPDFPreview'],
  computed: {
    fileId() {
      return this.$route.query.fileId;
    },
  },
  methods: {
    formatDate,
    numbersAlphabeticSort,
    isSelected(item: InboxFile): string {
      return this.highlightSelected && item.fileId === this.fileId ? 'focus' : '';
    },
  },
});
</script>
