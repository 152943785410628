<template>
  <div>
    <v-card-title>Slett fil</v-card-title>
    <v-card-text>
      Er du sikker på at du ønsker å slette denne filen?
      <br />
      Valg dersom du ønsker å slette filen:

      <v-radio-group v-model="moveToInbox">
        <v-radio :value="false" label="Slett fil" />
        <v-radio
          :value="true"
          :disabled="isBulkFileCreate"
          label="Slett fil og send tilbake til mottaket (godkjente filer)"
        />
      </v-radio-group>

      <div class="d-flex justify-end mt-4">
        <v-btn class="mr-2" text color="secondary" @click="cancel">Avbryt</v-btn>
        <v-btn
          :loading="isDeletingAttachment"
          :disabled="isDeletingAttachment"
          depressed
          color="error"
          @click="deleteAttachment"
        >
          Slett<v-icon right>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type { Entity } from '@/types';

enum Events {
  CANCEL = 'cancel',
  DELETED = 'deleted',
}

export default defineComponent({
  props: {
    fileId: {
      type: String,
      required: true,
    },
    entityId: {
      type: String as PropType<Entity['entityId']>,
      default: undefined,
    },
    entityType: {
      type: String as PropType<Entity['entityType']>,
      default: undefined,
    },
  },
  emits: Object.keys(Events),
  data: () => ({
    moveToInbox: false,
    isDeletingAttachment: false,
  }),
  computed: {
    isBulkFileCreate() {
      return this.$route.query.bulkFileCreate === 'true';
    },
    isOpeningBalance() {
      return this.$route.name === 'OpeningBalance';
    },
  },
  methods: {
    cancel() {
      this.$emit(Events.CANCEL);
    },
    async deleteAttachment() {
      this.isDeletingAttachment = true;

      const fileId = this.fileId;

      try {
        this.isOpeningBalance
          ? await this.$api.openingBalance.deleteAttachment(this.companyId, {
              moveToInbox: this.moveToInbox,
            })
          : await this.$api.file.deleteFile(this.companyId, fileId, {
              entityId: this.entityId,
              entityType: this.entityType,
              moveToInbox: this.moveToInbox,
            });
        this.$emit(Events.DELETED);
        this.$snackbar.success(this.moveToInbox ? 'Fil flyttet til filmottak' : 'Fil slettet');
      } catch (e) {
        this.$sentry.captureException(e);
      } finally {
        this.isDeletingAttachment = false;
      }
    },
  },
});
</script>
