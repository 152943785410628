import api from '@/utils/api';
import type {
  CompanyPayrollSettingsResponse,
  CompanyPayrollSettingsSaveBody,
  GetHolidayPayReportQuery,
  HolidayPayReportResponse,
  HolidayPayTemplateResponse,
  PensionProviderResponse,
  SalarySuggestionResponse,
  SalaryTypeCreateBody,
  SalaryTypeResponse,
  SalaryTypeTemplateUpdateBody,
  SalaryTypeUpdateBody,
} from '@/types/schema';

export const SalaryService = {
  getSalaryTypes(companyId: string): Promise<SalaryTypeResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/salary_types`,
      ignoreCache: true,
    });
  },
  getSalarySuggestion(
    companyId: string,
    params?: {
      startDate?: string;
      endDate?: string;
    },
  ): Promise<SalarySuggestionResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/salary_suggestion`,
      ignoreCache: true,
      params,
    });
  },
  getSalaryTypeTemplatesByOccupationCode(
    companyId: string,
    occupationCode: string,
    ignoreCache: boolean,
  ): Promise<SalaryTypeResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/salary_type_templates/${occupationCode}`,
      ignoreCache: ignoreCache,
    });
  },
  updateSalaryTypeTemplateByOccupationCode(
    companyId: string,
    occupationCode: string,
    data: SalaryTypeTemplateUpdateBody,
  ): Promise<void> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/salary_type_templates/${occupationCode}`,
      data: data,
    });
  },
  getPensionProviders(): Promise<PensionProviderResponse[]> {
    return api({
      method: 'get',
      url: `/pension_providers`,
      ignoreCache: true,
    });
  },
  getHolidayTemplates(): Promise<HolidayPayTemplateResponse[]> {
    return api({
      method: 'get',
      url: `/holiday_pay_templates`,
      ignoreCache: true,
    });
  },
  getHolidayPay(companyId: string, params: GetHolidayPayReportQuery): Promise<HolidayPayReportResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/holiday_pay`,
      params,
      abortKey: 'getHolidayPay',
    });
  },
  getPayrollSettings(companyId: string): Promise<CompanyPayrollSettingsResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/payroll_settings`,
      ignoreCache: true,
    });
  },
  setPayrollSettings(companyId: string, data: CompanyPayrollSettingsSaveBody): Promise<CompanyPayrollSettingsResponse> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/payroll_settings`,
      data,
    });
  },
  createSalaryType(companyId: string, data: SalaryTypeCreateBody): Promise<SalaryTypeResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/salary_types`,
      data,
    });
  },
  updateSalaryType(companyId: string, data: SalaryTypeUpdateBody, salaryType: string) {
    return api({
      method: 'patch',
      url: `companies/${companyId}/salary_types/${salaryType}`,
      data,
    });
  },
};
