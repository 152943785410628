<template>
  <StickyFooter>
    <v-row class="justify-space-between align-center no-gutters-y">
      <v-col>For markerte:</v-col>
      <v-col class="d-flex align-center justify-end">
        <v-btn
          v-if="tab === 0"
          outlined
          small
          color="success"
          :loading="loading.update"
          :disable="loading.update"
          @click="$emit('status', true)"
        >
          Godkjenn
        </v-btn>
        <template v-else-if="tab === 1">
          <v-btn
            small
            outlined
            color="error"
            :loading="loading.update"
            :disabled="loading.update"
            @click="$emit('status', false)"
          >
            Avvis
          </v-btn>
          <DropDownButton
            class="ml-4"
            color="primary"
            type="outline"
            title="Ny bokføring kjøp"
            small
            outlined
            @click.stop="$emit('navigate', 'PurchaseBulkCreate')"
          >
            <v-list>
              <v-list-item @click="$emit('navigate', 'SaleCreate')">Ny bokføring salg</v-list-item>
              <v-list-item @click="$emit('navigate', 'ManualTransaction')">Ny bokføring fri</v-list-item>
            </v-list>
          </DropDownButton>
        </template>
        <template v-else>
          <v-btn
            small
            outlined
            color="primary"
            :loading="loading.restore"
            :disabled="loading.restore"
            @click="$emit('restore')"
          >
            Gjenopprett
          </v-btn>
        </template>
        <v-menu v-if="tab !== 2" left>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" class="mr-4 ml-13" :loading="loading.split || loading.merge" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="$emit('split')">
              <v-list-item-icon>
                <v-icon>mdi-file-document-minus-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Splitt</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :disabled="!mergeAvailable" @click.prevent="$emit('merge')">
              <v-list-item-icon>
                <v-icon>mdi-file-document-plus-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Slå sammen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.prevent="$emit('delete')">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Slett</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </StickyFooter>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import DropDownButton from '@/components/DropDownButton.vue';
import StickyFooter from '@/components/StickyFooter.vue';

type Loading = {
  split: boolean;
  merge: boolean;
  update: boolean;
  restore: boolean;
};

export default defineComponent({
  components: {
    DropDownButton,
    StickyFooter,
  },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    fileId: {
      type: String,
      default: null,
    },
    mergeAvailable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Object as PropType<Loading>,
      default: (): Loading => ({
        split: false,
        merge: false,
        update: false,
        restore: false,
      }),
    },
  },
  emits: ['split', 'merge', 'status', 'navigate', 'delete', 'restore'],
});
</script>
<style lang="scss" scoped>
.v-application--is-ltr .v-list-item__icon:first-child::v-deep {
  margin-left: 0;
  margin-right: 16px;
}
</style>
