import { nanoid } from 'nanoid/non-secure';

const icons = {
  success: 'mdi-check-circle-outline',
  error: 'mdi-alert',
  warning: 'mdi-alert-outline',
  info: 'mdi-information-outline',
};

const timeoutDurations = {
  error: 15000,
  success: 5000,
  warning: 5000,
  info: 5000,
};

const getDefaultState = () => ({
  snackbars: [],
});

const state = getDefaultState();

const actions = {
  show({ commit }, { type, icon = icons[type], message, persistent = false }) {
    const snackbar = {
      id: nanoid(),
      type,
      icon,
      message,
      persistent,
    };

    commit('SHOW_SNACKBAR', snackbar);

    if (!persistent) {
      setTimeout(() => {
        commit('HIDE_SNACKBAR', snackbar.id);
      }, timeoutDurations[type]);
    }
  },
  hide({ commit }, snackbarId) {
    commit('HIDE_SNACKBAR', snackbarId);
  },
};

const mutations = {
  SHOW_SNACKBAR(state, snackbar) {
    state.snackbars.push(snackbar);
  },
  HIDE_SNACKBAR(state, snackbarId) {
    const index = state.snackbars.findIndex((s) => s.id === snackbarId);

    if (index !== -1) {
      state.snackbars.splice(index, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
