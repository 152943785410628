import api from '@/utils/api';
import type {
  EmployeeResponse,
  EmployeeCreateBody,
  EmployeeUpdateBody,
  EmployeeNumberSuggestionResponse,
  GetTaxCardBody,
  CreateCustomTaxCardBody,
  EmployeeSalaryDeductionCardResponse,
  EmployeeSalaryDeductionTableResponse,
  EmployeeHolidayPayResponse,
  GetEmployeeQuery,
} from '@/types/schema';

export const EmployeeService = {
  getEmployees(
    companyId: string,
    params: GetEmployeeQuery = {
      includeInactive: true,
    },
  ): Promise<EmployeeResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/employees`,
      params,
    });
  },
  getEmployeeById(companyId: string, id: string): Promise<EmployeeResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/employees/${id}`,
    });
  },
  createEmployee(companyId: string, employee: EmployeeCreateBody): Promise<EmployeeResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/employees`,
      data: employee,
    });
  },
  updateEmployee(companyId: string, employeeId: string, employee: EmployeeUpdateBody): Promise<EmployeeResponse> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/employees/${employeeId}`,
      data: employee,
    });
  },
  deleteEmployee(companyId: string, employeeId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/employees/${employeeId}`,
    });
  },
  employeeNumberSuggestion(companyId: string): Promise<EmployeeNumberSuggestionResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/employee_number_suggestion`,
    });
  },
  requestTaxCards(companyId: string, data?: GetTaxCardBody): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/employees/tax_cards`,
      data,
    });
  },
  saveManualTaxCard(
    companyId: string,
    employeeId: string,
    data: CreateCustomTaxCardBody,
  ): Promise<EmployeeSalaryDeductionCardResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/employees/${employeeId}/custom_tax_cards`,
      data,
    });
  },
  getDeductionTables(): Promise<EmployeeSalaryDeductionTableResponse[]> {
    return api({
      method: 'get',
      url: 'deduction_tables',
    });
  },
  importEmployees(companyId: string, file: File): Promise<EmployeeResponse[]> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/employees/import`,
      file,
    });
  },
  getEmployeeHolidayPay(companyId: string, employeeId: string, year: number): Promise<EmployeeHolidayPayResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/employees/${employeeId}/holidaypay`,
      params: {
        year,
      },
    });
  },
};
