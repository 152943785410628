import { type VueConstructor } from 'vue';

export type SnackbarOptions = {
  message: string | string[];
  type: 'error' | 'success' | 'warning' | 'info';
  persistent?: boolean;
  icon?: string;
};

export type Snackbar = Required<SnackbarOptions & { id: string }>;
export type Options = Omit<SnackbarOptions, 'type' | 'message'>;
export type SnackbarTypes = {
  [key in SnackbarOptions['type']]: (message: string | string[], options?: Options) => void;
};

const SnackbarPlugin = (Vue: VueConstructor, pluginOptions: any) => {
  if (!pluginOptions.store) {
    throw new Error('Please provide a store instance to the Snackbar plugin');
  }

  const store = pluginOptions.store;

  const snackbar: SnackbarTypes = {
    success(message: string | string[], options?: Options) {
      store.dispatch('snackbar/show', {
        type: 'success',
        message,
        ...options,
      });
    },
    error(message: string | string[], options?: Options) {
      store.dispatch('snackbar/show', {
        type: 'error',
        message,
        ...options,
      });
    },
    warning(message: string | string[], options?: Options) {
      store.dispatch('snackbar/show', {
        type: 'warning',
        message,
        ...options,
      });
    },
    info(message: string | string[], options?: Options) {
      store.dispatch('snackbar/show', {
        type: 'info',
        message,
        ...options,
      });
    },
  };

  Vue.prototype.$snackbar = snackbar;
};

export default SnackbarPlugin;
