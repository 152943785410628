import * as Sentry from '@sentry/vue';
import { TimesheetService } from '@/services/timesheetService';

const getDefaultState = () => ({
  activities: [],
  loading: false,
});

const state = getDefaultState();

const actions = {
  async fetchActivities({ commit }, { companyId }) {
    try {
      commit('SET_LOADING', true);
      const activities = await TimesheetService.getActivities(companyId, { isActive: true });
      commit('SET_ACTIVITIES', activities);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateActivity({ commit }, { companyId, activityId, payload }) {
    const activity = await TimesheetService.updateActivity(companyId, activityId, payload);
    commit('UPDATE_ACTIVITY', activity);
    return activity;
  },
  async createActivity({ commit }, { companyId, payload }) {
    const activity = await TimesheetService.createActivity(companyId, payload);
    commit('CREATE_ACTIVITY', activity);
    return activity;
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  SET_LOADING(state, bool) {
    state.loading = bool;
  },
  SET_ACTIVITIES(state, activities) {
    state.activities = activities;
  },
  CREATE_ACTIVITY(state, activity) {
    state.activities.push(activity);
  },
  UPDATE_ACTIVITY(state, activity) {
    const index = state.activities.findIndex((a) => a.id === activity.id);
    state.activities.splice(index, 1, activity);
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
