<template>
  <v-app class="unauthorized-layout">
    <AppSnackbar />
    <v-main fluid :style="$vuetify.theme.dark ? '' : 'background-color: #f5f5f5'">
      <div id="contentContainer">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppSnackbar from '@/components/app/AppSnackbar.vue';

export default defineComponent({
  components: {
    AppSnackbar,
  },
});
</script>
