import { EmployeeService } from '@/services/employeeService';
import { sortBy } from 'lodash-es';
import * as Sentry from '@sentry/vue';

const getDefaultState = () => ({
  employeesList: [],
});

const state = getDefaultState();

const getters = {
  getEmployees(state) {
    return sortBy(
      state.employeesList?.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName} (${item.number})`,
      })),
      ['firstName'],
    );
  },
};

const actions = {
  async createEmployee({ commit }, { companyId, data }) {
    const employee = await EmployeeService.createEmployee(companyId, data);
    commit('createEmployee', employee);

    return employee;
  },
  async deleteEmployee({ commit }, { companyId, employeeId }) {
    await EmployeeService.deleteEmployee(companyId, employeeId);
    commit('deleteEmployee', employeeId);
  },
  async getEmployeesList({ commit }, { companyId }) {
    try {
      const employees = await EmployeeService.getEmployees(companyId);
      commit('setEmployees', employees);
    } catch (e) {
      Sentry.captureException(e);
    }
  },
  resetState({ commit }) {
    commit('RESET_STATE');
  },
};

const mutations = {
  setEmployees(state, employees) {
    state.employeesList = employees || [];
  },
  createEmployee(state, employee) {
    state.employeesList.push(employee);
  },
  deleteEmployee(state, employeeId) {
    state.employeesList = state.employeesList.filter((employee) => employee.id !== employeeId);
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
