<template>
  <v-app
    :class="{
      'nav-drawer--minimized': isNavDrawerMinimized,
      'nav-drawer--visible': isNavDrawerVisible,
      'resizable-sidebar--visible': isAttachmentViewerVisible,
    }"
  >
    <AppNavDrawer />
    <AppBar />
    <AppSnackbar />
    <v-main ref="mainRef" style="background-color: #f2f3f8; padding-top: 110px">
      <v-container id="contentContainer" fluid class="wrapper-container pt-0">
        <v-row v-if="isLoading || handlingConsentCallback" class="ma-2" align="center" justify="center">
          <v-progress-circular indeterminate color="primary" />
        </v-row>
        <router-view v-else :id="isAttachmentViewerVisible && 'contentInnerContainer'" :key="selectedCompany.id" />
      </v-container>
      <AttachmentViewer v-if="isAttachmentViewerVisible" />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, provide, ref } from 'vue';
import { mapGetters } from 'vuex';
import { useElementSize } from '@vueuse/core';
import AppNavDrawer from '@/components/app-nav-drawer/AppNavDrawer.vue';
import AppBar from '@/components/app-bar/AppBar.vue';
import AppSnackbar from '@/components/app/AppSnackbar.vue';
import AttachmentViewer from '@/components/attachment/AttachmentViewer.vue';

export default defineComponent({
  components: {
    AppNavDrawer,
    AppBar,
    AttachmentViewer,
    AppSnackbar,
  },
  setup() {
    const mainRef = ref<HTMLElement | null>(null);
    const mainSize = useElementSize(mainRef);

    provide('mainContainerSize', mainSize);

    return {
      mainRef,
    };
  },
  data: () => ({
    isLoading: false,
    handlingConsentCallback: false,
  }),
  computed: {
    ...mapGetters({
      selectedCompany: 'selectedCompany',
      isNavDrawerMinimized: 'theme/getNavDrawerMinimized',
      isNavDrawerVisible: 'theme/getNavDrawerVisible',
    }),
    isLoadingCompany() {
      return this.$store.state.company.isLoadingCompany;
    },
    isAttachmentViewerVisible() {
      return (
        this.$route.query.fileId || this.$route.meta?.newAttachment || this.$store.state.attachmentViewer.isVisible
      );
    },
  },
  watch: {
    '$route.fullPath'() {
      this.isLoading = false;
    },
    isLoadingCompany(value) {
      if (value) {
        this.isLoading = true;
      }
    },
  },
  created() {
    this.handleConsentRedirectCallback();
  },
  methods: {
    async handleConsentRedirectCallback() {
      const consentSuccess = this.$route.query.consentSuccess as string | undefined;
      const instructionId = this.$route.query.instructionId as string | undefined;
      const naasConsentReference = this.$route.query.naasConsentReference as string | undefined;

      if (!consentSuccess || !instructionId || !naasConsentReference) return;

      try {
        this.handlingConsentCallback = true;

        await this.$api.bankIntegration.saveConsentResult(this.companyId, {
          instructionId,
          naasConsentReference,
        });
        await this.$api.bankIntegration.updateCompanyOnboardingStatus(this.companyId);
        await this.$store.dispatch('bankIntegration/getCompanyOnboardingStatus', { companyId: this.companyId });

        await this.$router.replace({
          query: {
            consentSuccess: undefined,
            instructionId: undefined,
            naasConsentReference: undefined,
          },
        });
      } catch (err: any) {
        this.$sentry.captureException(err);
      } finally {
        this.handlingConsentCallback = false;
      }
    },
  },
});
</script>
