import mitt from 'mitt';
import type { FileResponse } from '@/types/schema';

type Events = {
  fileUploaded: FileResponse;
  bankRecFileUploaded: FileResponse;
  fileDeleted: string | undefined;
};

export default mitt<Events>();
