<template>
  <transition-group name="snackbar" tag="div" class="snackbar-container">
    <div v-for="snackbar in snackbars" :key="snackbar.id" class="snackbar-wrapper">
      <v-snackbar :value="true" :color="snackbar.type" :timeout="-1" class="snackbar-item">
        <div class="d-flex">
          <v-icon v-if="snackbar.icon" class="snackbar-item__icon">{{ snackbar.icon }}</v-icon>
          <div class="snackbar-item__content">
            <ul v-if="Array.isArray(snackbar.message)" class="error-list">
              <li v-for="message of snackbar.message" :key="message" v-html="message" />
            </ul>
            <div v-else v-html="snackbar.message" />
          </div>
        </div>
        <template #action>
          <v-btn icon @click.stop="hideSnackbar(snackbar)">
            <v-icon class="snackbar-item__close">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </transition-group>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { type Snackbar } from '@/plugins/snackbar';

export default defineComponent({
  computed: {
    snackbars(): Snackbar[] {
      return this.$store.state.snackbar.snackbars;
    },
  },
  methods: {
    hideSnackbar(snackbar: Snackbar) {
      this.$store.dispatch('snackbar/hide', snackbar.id);
    },
  },
});
</script>

<style lang="scss" scoped>
.snackbar-enter-active,
.snackbar-leave-active {
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}
.snackbar-enter {
  opacity: 0;
}
.snackbar-leave-to {
  opacity: 0;
}

.snackbar-container {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  pointer-events: none;
}

::v-deep .v-snack:not(.v-snack--absolute) {
  position: static !important;
  pointer-events: none !important;
  height: auto !important;
}

.snackbar-wrapper {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  pointer-events: none;

  ::v-deep .v-snack__wrapper {
    pointer-events: auto;
    border-radius: 10px;

    color: black;

    &.success {
      background: var(--v-success-lighten2) !important;
      border: 1px solid var(--v-success-base) !important;
    }
    &.warning {
      background: var(--v-warning-lighten2) !important;
      border: 1px solid var(--v-warning-base) !important;
    }
    &.error {
      background: var(--v-red-lighten2) !important;
      border: 1px solid var(--v-red-base) !important;
    }
    &.info {
      background: var(--v-info-lighten2) !important;
      border: 1px solid var(--v-info-base) !important;
    }

    .snackbar-item__close,
    .snackbar-item__icon {
      color: black;
    }
    .snackbar-item__content {
      padding: 3px 10px;
      font-weight: 500;
      a {
        color: var(--v-primary-base) !important;
      }
    }
  }
}
.snackbar-item {
  margin-bottom: 0;
  pointer-events: auto;
}
.error-list {
  list-style: none;
  li:before {
    margin-right: 2px;
  }
}
// reset default vuetify ul padding
ul {
  padding-left: 0 !important;
}
</style>
