<template>
  <v-navigation-drawer
    :value="isNavDrawerVisible"
    :width="248"
    :mini-variant="isNavDrawerMinimized"
    :mini-variant-width="64"
    :expand-on-hover.sync="isNavDrawerMinimized"
    app
    class="app-nav-drawer"
  >
    <template #prepend>
      <div class="relative">
        <router-link
          :to="$route.params.companyNameSlug ? { name: 'Dashboard' } : { name: 'SelectCompany' }"
          class="d-flex my-6"
        >
          <img
            height="32px"
            :src="isNavDrawerMinimized ? '/assets/systima_icon.png' : '/assets/systima_full.png'"
            :class="isNavDrawerMinimized ? 'mx-auto' : 'ml-6'"
          />
        </router-link>
        <v-btn absolute fab width="32" height="32" style="top: 0; right: -16px" @click="toggleMiniVariant">
          <v-icon>{{ miniVariantIcon }}</v-icon>
        </v-btn>
      </div>
    </template>

    <v-list dense nav class="px-2">
      <template v-for="(item, index) in itemsFiltered">
        <ListGroup
          v-if="
            (item.canAccess || item.canAccess === undefined) &&
            item.items?.filter((i) => i.canAccess || i.canAccess === undefined).length
          "
          :key="`group-${index}`"
          :item="item"
        />
        <ListItem v-else-if="item.canAccess || item.canAccess === undefined" :key="`item-${index}`" :item="item" />
      </template>
    </v-list>

    <template #append>
      <v-list dense nav class="px-2 pt-3 pb-6">
        <template v-if="!isNavDrawerMinimized">
          <ListItem :item="{ text: fullName, icon: 'mdi-account-circle-outline' }" class="name" />
          <v-divider class="mb-3" />
        </template>
        <ListItem
          :item="{
            text: 'Min profil',
            icon: 'mdi-cog-outline',
            to: { name: 'Profile' },
          }"
        />
        <ListItem
          :item="{
            text: 'Logg ut',
            icon: 'mdi-logout',
            to: { name: 'Logout' },
          }"
        />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import ListGroup from './ListGroup.vue';
import ListItem from './ListItem.vue';
import { SubscriptionModule } from '@/types';
import type { Location } from 'vue-router';
import type { CompanySubscriptionPlanResponse, UserResponse } from '@/types/schema';

export type MenuItem = {
  text: string;
  icon?: string;
  iconClass?: string;
  img?: string;
  to?: string | Location;
  company?: boolean;
  modules?: SubscriptionModule[];
  systemRole?: UserResponse['systemRole'];
  exact?: boolean;
  items?: MenuItem[];
  canAccess?: boolean;
};

export default defineComponent({
  components: {
    ListGroup,
    ListItem,
  },
  computed: {
    ...mapState('subscription', {
      currentSubscription: (state: any) => state.currentSubscription,
    }),
    ...mapGetters({
      advancedMode: 'theme/getAdvancedMode',
      selectedCompany: 'selectedCompany',
      companyIsOnboarded: 'bankIntegration/getCompanyOnboardingStatus',
      isNavDrawerMinimized: 'theme/getNavDrawerMinimized',
      isNavDrawerVisible: 'theme/getNavDrawerVisible',
    }),
    subscription(): CompanySubscriptionPlanResponse | null {
      return this.$store.getters['subscription/getSelectedCompanySubscription'];
    },
    companies() {
      return this.$store.getters.companies as any[];
    },
    company() {
      return this.$store.getters.getCompanyById(this.companyId);
    },
    companyNameSlug() {
      return this.$store.getters.getCompanyNameSlugById(this.companyId);
    },
    user() {
      return this.$store.getters.getUser as UserResponse;
    },
    fullName() {
      return `${this.user?.firstName} ${this.user?.lastName}`;
    },
    isAuthUserOwner(): boolean {
      return this.$store.getters['company/isAuthUserOwner'];
    },
    items(): MenuItem[] {
      if (!this.$route.params.companyNameSlug) {
        return [
          {
            text: 'Selskapsoversikt',
            icon: 'mdi-home-city-outline',
            to: { name: 'SelectCompany' },
            exact: true,
          },
          ...(this.user?.accountingFirmId
            ? [
                {
                  text: 'Mitt regnskapsbyrå',
                  icon: 'mdi-badge-account-horizontal-outline',
                  iconClass: 'persian--text',
                  to: { name: 'AccountingFirm' },
                },
              ]
            : []),
          ...(this.user?.systemRole !== 'COMMON'
            ? [
                {
                  text: 'Systima CP',
                  img: '/assets/systima_icon.png',
                  to: { name: 'ControlPanel' },
                },
              ]
            : []),
        ];
      }

      if (this.advancedMode) {
        const menu: MenuItem[] = [
          {
            text: 'Selskapsoversikt',
            icon: 'mdi-home-city-outline',
            to: { name: 'SelectCompany' },
            exact: true,
          },
          {
            text: 'Oversikt',
            icon: 'mdi-home-outline',
            iconClass: 'rainforest--text',
            to: { name: 'Dashboard', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
          },
          ...(this.subscription?.subscriptionPlan.code === 'salary'
            ? [
                {
                  text: 'Filmottak',
                  icon: 'mdi-book-plus-outline',
                  to: {
                    name: 'FileInbox',
                    params: { companyNameSlug: this.companyNameSlug },
                  },
                  company: true,
                  modules: [SubscriptionModule.SALARY],
                  canAccess: this.$can('company.fileInbox.read'),
                },
              ]
            : [
                {
                  text: 'Bokføring',
                  icon: 'mdi-book-plus-outline',
                  iconClass: 'orangeJuice--text',
                  company: true,
                  modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                  items: [
                    {
                      text: 'Filmottak',
                      to: {
                        name: 'FileInbox',
                        params: { companyNameSlug: this.companyNameSlug },
                      },
                      company: true,
                      modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                    },
                    {
                      text: 'Bokfør godkjente filer',
                      to: {
                        name: 'PurchaseBulkCreate',
                        params: { companyNameSlug: this.companyNameSlug },
                        query: {
                          // preserve query params if already on the page (skips navigation basically)
                          ...(this.$route.name === 'PurchaseBulkCreate' ? { ...this.$route.query } : {}),
                        },
                      },
                      company: true,
                      modules: [SubscriptionModule.ACCOUNTING],
                      canAccess: this.$can('company.bookkeeping.read', 'company.fileInbox.adminApproved'),
                    },
                    {
                      text: 'Bokfør andre filer',
                      to: { name: 'PurchaseCreate', params: { companyNameSlug: this.companyNameSlug } },
                      company: true,
                      modules: [SubscriptionModule.ACCOUNTING],
                      canAccess: this.$can('company.bookkeeping.read'),
                    },
                    {
                      text: 'Mva. rapport',
                      to: { name: 'MVAReportIndex', params: { companyNameSlug: this.companyNameSlug } },
                      company: true,
                      modules: [SubscriptionModule.ACCOUNTING],
                      canAccess: this.$can('company.vatReport.read'),
                    },
                  ],
                },
              ]),
          ...(this.companyIsOnboarded
            ? [
                {
                  text: 'Betalinger',
                  icon: 'mdi-cash-multiple',
                  iconClass: 'salmon--text',
                  to: { name: 'PaymentOverviewIndex', params: { companyNameSlug: this.companyNameSlug } },
                  company: true,
                  exact: true,
                  modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                  canAccess: this.$can('company.outgoingPayment.read'),
                },
              ]
            : []),
          {
            text: 'Bankavstemming',
            icon: 'mdi-human-male-board-poll',
            iconClass: 'yellowGreen--text',
            to: { name: 'BankReconciliationIndex', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.ACCOUNTING],
            canAccess: this.$can('company.bankReconciliation.read'),
          },
          {
            text: 'Faktura',
            icon: 'mdi-text-box-plus-outline',
            iconClass: 'lavender--text',
            company: true,
            to: { name: 'Invoices', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
            canAccess: this.$can('company.invoicing.read'),
          },
          {
            text: 'Kontakter',
            icon: 'mdi-account-multiple-outline',
            iconClass: 'carmine--text',
            to: { name: 'Contacts', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
            canAccess: this.$can('company.contact.read'),
          },
          {
            text: 'Produkter',
            icon: 'mdi-cube-outline',
            iconClass: 'teal--text',
            company: true,
            to: { name: 'Products', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
            canAccess: this.$can('company.product.read'),
          },
          {
            text: 'Ansatte',
            icon: 'mdi-badge-account-outline',
            iconClass: 'dynamite--text',
            company: true,
            to: { name: 'Employees', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
            canAccess: this.$can('company.employee.read'),
          },
          {
            text: 'Timeføring',
            icon: 'mdi-clock-outline',
            iconClass: 'mango--text',
            company: true,
            to: { name: 'Timesheet', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY, SubscriptionModule.TIMESHEET],
            canAccess: this.$can('company.timesheet.read'),
          },
          {
            text: 'Lønn',
            icon: 'mdi-credit-card-outline',
            iconClass: 'purple--text',
            company: true,
            to: { name: 'Salary', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
            canAccess: this.$can('company.salary.read'),
          },
          {
            text: 'Rapporter',
            icon: 'mdi-chart-scatter-plot-hexbin',
            iconClass: 'paradisePink--text',
            company: true,
            modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
            items: [
              {
                text: 'Balanserapport',
                to: {
                  name: 'BalanceReport',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.report.balance'),
              },
              {
                text: 'Bilagsjournal',
                to: { name: 'Vouchers', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.report.voucher'),
              },
              {
                text: 'Feriepengeliste',
                to: { name: 'HolidayPay', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.SALARY],
                canAccess: this.$can('company.report.holidayPay'),
              },
              {
                text: 'Hovedbok',
                to: { name: 'GeneralLedgerIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.report.generalLedger'),
              },
              {
                text: 'Mva. spesifikasjon',
                to: { name: 'VatSpecification', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
                canAccess: this.$can('company.report.vatSpecification'),
              },
              {
                text: 'Reskontro',
                to: {
                  name: 'SubAccounts',
                  params: {
                    companyNameSlug: this.companyNameSlug,
                    type:
                      this.subscription?.subscriptionPlan.code === 'salary' &&
                      this.$can('company.report.subAccountEmployee')
                        ? 'employee'
                        : this.$can('company.report.subAccountSupplier')
                          ? 'supplier'
                          : 'customer',
                  },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$canSome(
                  'company.report.subAccountSupplier',
                  'company.report.subAccountCustomer',
                  'company.report.subAccountEmployee',
                ),
              },
              {
                text: 'Resultatrapport',
                to: {
                  name: 'IncomeReport',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.report.income'),
              },
              {
                text: 'Saldobalanse',
                to: { name: 'TrialBalanceIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.report.trialBalance'),
              },
              {
                text: 'Sammenstillingsrapport',
                to: { name: 'CompilationReport', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.SALARY],
                canAccess: this.$can('company.report.compilationReport'),
              },
            ],
            canAccess: this.$can('company.report.read'),
          },
          {
            text: 'Innstillinger',
            icon: 'mdi-cog-outline',
            iconClass: 'fightTheSunrise--text',
            canAccess: this.$can('company.settings.read') || this.isAuthUserOwner,
            items: [
              {
                text: 'Avdelinger',
                to: { name: 'Departments', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.department'),
              },
              {
                text: 'Bankavstemninginnst',
                to: { name: 'BankReconciliationSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
                canAccess: this.$can('company.settings.bankReconciliation'),
              },
              {
                text: 'Betalingsinnstillinger',
                to: { name: 'PaymentSettingsIndex', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.outgoingPayment'),
              },
              {
                text: 'Brukertilgang',
                to: { name: 'UserAccess', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                canAccess: this.$can('company.user.read') || this.isAuthUserOwner,
              },
              {
                text: 'Fakturainnstillinger',
                to: {
                  name: 'InvoiceSettings',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.INVOICE],
                canAccess: this.$can('company.settings.invoicing'),
              },
              {
                text: 'Integrasjoner',
                to: { name: 'IntegrationsSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
                canAccess: this.$can('company.settings.integration'),
              },
              {
                text: 'Kontoplan',
                to: { name: 'ChartOfAccounts', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.account'),
              },
              {
                text: 'Lønnsinnstillinger',
                to: { name: 'SalarySettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.salary'),
              },
              {
                text: 'Låste perioder',
                to: { name: 'LockPeriodSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.lockedPeriod'),
              },
              {
                text: 'Moduler',
                to: { name: 'Modules', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.module'),
              },
              {
                text: 'Mva. innstillinger',
                to: { name: 'VatCodesSettings', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING],
                canAccess: this.$can('company.settings.vatCode'),
              },
              {
                text: 'Prosjekter',
                to: { name: 'Projects', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.project'),
              },
              {
                text: 'Selskapsinnstillinger',
                to: { name: 'Settings', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.company'),
              },
              {
                text: 'Timeføringsinnstillinger',
                to: { name: 'TimesheetsSettings', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.timesheet'),
              },
              {
                text: 'Åpningsbalanse',
                to: { name: 'OpeningBalance', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.openingBalance'),
              },
            ],
          },
        ];
        if (this.user?.accountingFirmId) {
          menu.push({
            text: 'Mitt regnskapsbyrå',
            icon: 'mdi-badge-account-horizontal-outline',
            iconClass: 'persian--text',
            to: { name: 'AccountingFirm' },
          });
        }
        if (this.user?.systemRole !== 'COMMON') {
          menu.push({
            text: 'Systima CP',
            img: '/assets/systima_icon.png',
            to: { name: 'ControlPanel' },
          });
        }

        return menu;
      } else {
        const menu: MenuItem[] = [
          {
            text: 'Selskapsoversikt',
            icon: 'mdi-home-city-outline',
            to: { name: 'SelectCompany' },
            exact: true,
          },
          {
            text: 'Oversikt',
            icon: 'mdi-home-outline',
            iconClass: 'rainforest--text',
            to: { name: 'Dashboard', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
          },
          {
            text: 'Faktura',
            icon: 'mdi-text-box-plus-outline',
            iconClass: 'lavender--text',
            company: true,
            to: { name: 'Invoices', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
            canAccess: this.$can('company.invoicing.read'),
          },
          {
            text: 'Filmottak',
            icon: 'mdi-book-plus-outline',
            company: true,
            to: { name: 'FileInbox', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
            canAccess: this.$can('company.fileInbox.read'),
          },
          ...(this.companyIsOnboarded
            ? [
                {
                  text: 'Betalinger',
                  icon: 'mdi-cash-multiple',
                  iconClass: 'salmon--text',
                  to: { name: 'PaymentOverviewIndex', params: { companyNameSlug: this.companyNameSlug } },
                  company: true,
                  exact: true,
                  modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                  canAccess: this.$can('company.outgoingPayment.read'),
                },
              ]
            : []),
          {
            text: 'Kontakter',
            icon: 'mdi-account-multiple-outline',
            iconClass: 'carmine--text',
            to: { name: 'Contacts', params: { companyNameSlug: this.companyNameSlug } },
            company: true,
            modules: [SubscriptionModule.INVOICE],
            canAccess: this.$can('company.contact.read'),
          },
          {
            text: 'Produkter',
            icon: 'mdi-cube-outline',
            iconClass: 'teal--text',
            company: true,
            to: { name: 'Products', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.INVOICE],
            canAccess: this.$can('company.product.read'),
          },
          {
            text: 'Ansatte',
            icon: 'mdi-badge-account-outline',
            iconClass: 'dynamite--text',
            company: true,
            to: { name: 'Employees', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
            canAccess: this.$can('company.employee.read'),
          },
          {
            text: 'Timeføring',
            icon: 'mdi-clock-outline',
            iconClass: 'mango--text',
            company: true,
            to: { name: 'Timesheet', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY, SubscriptionModule.TIMESHEET],
            canAccess: this.$can('company.timesheet.read'),
          },
          {
            text: 'Lønn',
            icon: 'mdi-credit-card-outline',
            iconClass: 'purple--text',
            company: true,
            to: { name: 'Salary', params: { companyNameSlug: this.companyNameSlug } },
            modules: [SubscriptionModule.SALARY],
            canAccess: this.$can('company.salary.read'),
          },
          {
            text: 'Rapporter',
            icon: 'mdi-chart-scatter-plot-hexbin',
            iconClass: 'paradisePink--text',
            company: true,
            modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
            items: [
              {
                text: 'Balanserapport',
                to: {
                  name: 'BalanceReport',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.report.balance'),
              },
              {
                text: 'Resultatrapport',
                to: {
                  name: 'IncomeReport',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.ACCOUNTING, SubscriptionModule.SALARY],
                canAccess: this.$can('company.report.income'),
              },
            ],
            canAccess: this.$can('company.report.read'),
          },
          {
            text: 'Innstillinger',
            icon: 'mdi-cog-outline',
            iconClass: 'fightTheSunrise--text',
            canAccess: this.$can('company.settings.read') || this.isAuthUserOwner,
            items: [
              {
                text: 'Avdelinger',
                to: { name: 'Departments', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.department'),
              },
              {
                text: 'Brukertilgang',
                to: { name: 'UserAccess', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                canAccess: this.$can('company.user.read') || this.isAuthUserOwner,
              },
              {
                text: 'Fakturainnstillinger',
                to: {
                  name: 'InvoiceSettings',
                  params: { companyNameSlug: this.companyNameSlug },
                },
                company: true,
                modules: [SubscriptionModule.INVOICE],
                canAccess: this.$can('company.settings.invoicing'),
              },
              {
                text: 'Moduler',
                to: { name: 'Modules', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.module'),
              },
              {
                text: 'Prosjekter',
                to: { name: 'Projects', params: { companyNameSlug: this.companyNameSlug } },
                company: true,
                modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.project'),
              },
              {
                text: 'Selskapsinnstillinger',
                to: { name: 'Settings', params: { companyNameSlug: this.companyNameSlug } },
                modules: [SubscriptionModule.INVOICE, SubscriptionModule.SALARY],
                canAccess: this.$can('company.settings.company'),
              },
            ],
          },
        ];
        if (this.user?.accountingFirmId) {
          menu.push({
            text: 'Mitt regnskapsbyrå',
            icon: 'mdi-badge-account-horizontal-outline',
            iconClass: 'persian--text',
            to: { name: 'AccountingFirm' },
          });
        }
        if (this.user?.systemRole !== 'COMMON') {
          menu.push({
            text: 'Systima CP',
            img: '/assets/systima_icon.png',
            to: { name: 'ControlPanel' },
          });
        }

        return menu;
      }
    },
    itemsFiltered(): MenuItem[] {
      const modules: any[] = this.subscription?.subscriptionPlan.modules || [];

      const filterFn = (item: MenuItem) => {
        const hasCompanies = !item.company || this.companies.length;
        const hasModule = !item.modules || modules.some((module) => item.modules?.includes(module));
        const hasRole = !item.systemRole || this.user?.systemRole === item.systemRole;

        if (item.items) {
          item.items = item.items.filter(filterFn);
        }

        return hasCompanies && hasModule && hasRole;
      };

      const items = JSON.parse(JSON.stringify(this.items));

      return items.filter(filterFn);
    },
    miniVariantIcon() {
      return this.isNavDrawerMinimized ? 'mdi-chevron-right' : 'mdi-chevron-left';
    },
  },
  methods: {
    toggleMiniVariant() {
      this.$store.dispatch('theme/toggleNavDrawerMinimized');
    },
  },
});
</script>

<style lang="scss">
.app-nav-drawer {
  overflow: visible !important;

  &.v-navigation-drawer--mini-variant .v-list-item > *:first-child {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &:not(.v-navigation-drawer--mini-variant) {
    .v-list-item {
      padding-left: 12px !important;
      padding-right: 12px !important;
      .v-list-item__icon {
        margin-right: 12px !important;
      }
    }
  }

  .v-list-item {
    border-radius: 12px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::before {
      border-radius: 12px !important;
    }
    .v-list-group__header__append-icon {
      margin-left: 12px !important;
      margin-right: 0 !important;
      min-width: 24px !important;
    }
    .v-list-item__title {
      font-size: 14px !important;
      line-height: 24px !important;
      color: var(--v-darkBlue-base) !important;
    }

    &.v-list-item--active {
      .v-icon {
        color: rgba(0, 0, 0, 0.54);
      }
      .v-list-item__title {
        font-weight: bold !important;
      }
    }
  }

  .v-list-group__header.v-list-item--active:not(:hover):not(:focus):before {
    opacity: 0.12 !important;
  }

  .v-list-group__items {
    margin-left: 24px !important;
    border-left: 1px solid rgba(224, 224, 224, 1);
    padding-left: 12px !important;
  }

  .v-navigation-drawer__content {
    .v-list-item {
      margin-bottom: 8px !important;
    }
  }

  .v-navigation-drawer__append {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0px -4px 12px 0px rgba(117, 117, 117, 0.12);

    .v-list-item {
      margin-bottom: 0 !important;
      &.name {
        min-height: 48px;
        .v-list-item__icon {
          margin-top: 12px !important;
          margin-bottom: 12px !important;
        }
        .v-list-item__title {
          font-size: 16px !important;
        }
      }
    }
  }
}
</style>
