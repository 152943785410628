import api from '@/utils/api';
import type {
  BankReconciliationCreateBody,
  BankReconciliationResponse,
  BankReconciliationStatusResponse,
  BankManualReconciliationCreateBody,
  GeneralLedgerResponse,
  IncomeSaveBody,
  ManualTransactionCreateBody,
  PaymentSourceCreateBody,
  PaymentSourceResponse,
  PaymentSourceUpdateBody,
  PurchaseSaveBody,
  BankReconciliationUpdateBody,
  BookkeepingStatusResponse,
  BankReconciliationReopenBody,
  BankAutoReconciliationUpdateBody,
  ManualTransactionResponse,
  TrialBalanceResponse,
  BalanceSheetResponse,
  IncomeStatementResponse,
  PurchaseResponse,
  IncomeResponse,
  PayslipResponse,
  BankReconciliationSettingsEquityResponse,
  BankReconciliationSettingsEquityRequest,
  BaseManualTransactionResponse,
  BankAutoSuggestionUpdateBody,
  GetEmployeeCompilationReportResponse,
  ShortcutManualTransactionCreateBody,
  ManualTransactionCreateReconcileBody,
  PurchaseSaveReconcileBody,
  IncomeSaveReconcileBody,
  CurrencyResponse,
  GetGeneralLedgerQuery,
  PaginationQueryRequest,
} from '@/types/schema';
import type { Entity } from '@/types';
import type { CompilationReportFilters } from '@/modules/accounting/types/compilation-report';
import type { PaginatedResponse } from '@/utils/pagination';

export const AccountingService = {
  getPaymentSources(companyId: string): Promise<PaymentSourceResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/payment_sources`,
      ignoreCache: true,
    });
  },
  getBankReconciliationsStatus(companyId: string, period: string): Promise<BankReconciliationStatusResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/bank_reconciliations_status`,
      params: {
        period,
      },
      ignoreCache: true,
    });
  },
  storePaymentSource(companyId: string, paymentSource: PaymentSourceCreateBody): Promise<PaymentSourceResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/payment_sources`,
      data: paymentSource,
    });
  },
  updatePaymentSource(
    companyId: string,
    paymentSource: PaymentSourceUpdateBody & { id: string },
  ): Promise<PaymentSourceResponse> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/payment_sources/${paymentSource.id}`,
      data: paymentSource,
    });
  },
  deletePaymentSource(companyId: string, paymentSourceId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `companies/${companyId}/payment_sources/${paymentSourceId}`,
    });
  },
  getGeneralLedger(
    companyId: string,
    filters: GetGeneralLedgerQuery & PaginationQueryRequest,
  ): Promise<PaginatedResponse<GeneralLedgerResponse, { hasDepartments: boolean; hasProjects: boolean }>> {
    const date = +new Date();
    return api({
      method: 'get',
      url: `companies/${companyId}/general_ledger_v2`,
      params: {
        time: date,
        ...filters,
      },
      abortKey: 'getGeneralLedger',
    });
  },
  createBankReconciliation(
    companyId: string,
    bankAccountId: string,
    payload: BankReconciliationCreateBody,
  ): Promise<BankReconciliationResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/bankreconciliations/${bankAccountId}`,
      data: payload,
    });
  },
  updateBankManualReconciliation(
    companyId: string,
    bankAccountId: string,
    journalEntryLineId: string,
    payload: BankManualReconciliationCreateBody,
  ): Promise<void> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/bankreconciliations/${bankAccountId}/reconcile/${journalEntryLineId}`,
      data: payload,
    });
  },
  updateReconciliation(
    companyId: string,
    bankAccountId: string,
    payload: BankReconciliationUpdateBody,
  ): Promise<BankReconciliationResponse> {
    return api({
      method: 'patch',
      url: `companies/${companyId}/bankreconciliations/${bankAccountId}`,
      data: payload,
    });
  },
  getBookKeepingStatus(companyId: string): Promise<BookkeepingStatusResponse> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/bookkeeping_status`,
      ignoreCache: true,
    });
  },
  getReconciliatedPeriods(
    companyId: string,
    bankAccountId: string,
    params: {
      companyId: string;
      bankAccountId: string;
      month?: string;
    },
  ): Promise<string[]> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/bankreconciliations/${bankAccountId}/reconciled`,
      ignoreCache: true,
      params,
    });
  },
  reOpenReconciliation(companyId: string, bankAccountId: string, payload: BankReconciliationReopenBody): Promise<void> {
    return api({
      method: 'patch',
      url: `/companies/${companyId}/bankreconciliations/${bankAccountId}/batch_open`,
      ignoreCache: true,
      data: payload,
    });
  },
  bankAutoReconciliationReconcileItems(
    companyId: string,
    bankAccountId: string,
    payload: BankAutoReconciliationUpdateBody,
  ): Promise<BankReconciliationResponse> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/bankreconciliations/${bankAccountId}/reconcile`,
      ignoreCache: true,
      data: payload,
    });
  },
  createManualTransactionByAccountId(
    companyId: string,
    accountId: string,
    payload: ShortcutManualTransactionCreateBody,
  ): Promise<ManualTransactionResponse> {
    return api({
      method: 'put',
      url: `/companies/${companyId}/shortcut/transactions/${accountId}`,
      data: payload,
    });
  },
  declineReconciliationSuggestion(
    companyId: string,
    bankAccountId: string,
    payload: BankAutoSuggestionUpdateBody,
  ): Promise<BankReconciliationResponse> {
    return api({
      method: 'delete',
      url: `/companies/${companyId}/bankreconciliations/${bankAccountId}/suggestion`,
      ignoreCache: true,
      data: payload,
    });
  },
  bankAutoReconciliationReconcileItemsDelete(
    companyId: string,
    bankAccountId: string,
    payload: BankAutoReconciliationUpdateBody,
  ): Promise<BankReconciliationResponse> {
    return api({
      method: 'delete',
      url: `/companies/${companyId}/bankreconciliations/${bankAccountId}/reconcile`,
      ignoreCache: true,
      data: payload,
    });
  },
  storeTransaction(companyId: string, data: ManualTransactionCreateReconcileBody): Promise<ManualTransactionResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/transactions`,
      data,
    });
  },
  reconcileTransaction(companyId: string, data: ManualTransactionCreateBody): Promise<ManualTransactionResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/transactions/reconcile`,
      data,
    });
  },
  editTransaction(
    companyId: string,
    data: ManualTransactionCreateBody,
    transactionId: string,
  ): Promise<ManualTransactionResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/transactions/${transactionId}`,
      data,
    });
  },
  getTransaction(
    companyId: string,
    id: string,
  ): Promise<
    ManualTransactionResponse & {
      // Temporary solution until the response type is updated to include these
      originalEntity?: BaseManualTransactionResponse;
      reversedEntity?: BaseManualTransactionResponse;
    }
  > {
    const date = +new Date();
    return api({
      method: 'get',
      url: `companies/${companyId}/transactions/${id}?time=${date}`,
    });
  },
  reverseTransaction(companyId: string, id: string, date: string): Promise<ManualTransactionResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/transactions/${id}/reverse`,
      ignoreCache: true,
      params: {
        date,
      },
    });
  },
  reversePurchase(companyId: string, id: string, date: string): Promise<PurchaseResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/purchases/${id}/reverse`,
      ignoreCache: true,
      params: {
        date,
      },
    });
  },
  reverseSale(companyId: string, id: string, date: string): Promise<IncomeResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/incomes/${id}/reverse`,
      ignoreCache: true,
      params: {
        date,
      },
    });
  },
  getTrialBalanceList(companyId: string, startDate: string, endDate: string): Promise<TrialBalanceResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/trial_balance`,
      ignoreCache: true,
      params: {
        startDate,
        endDate,
      },
      abortKey: 'getTrialBalanceList',
    });
  },
  getBalanceSheetList(
    companyId: string,
    filters?: { startDate?: string; endDate?: string; projectIds?: string; departmentIds?: string },
  ): Promise<BalanceSheetResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/balance_sheet`,
      ignoreCache: true,
      params: {
        ...filters,
      },
      abortKey: 'getBalanceSheetList',
    });
  },
  getIncomeStatement(
    companyId: string,
    filters: {
      startDate: string;
      endDate: string;
      projectIds: string;
      departmentIds: string;
    },
  ): Promise<IncomeStatementResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/income_statement`,
      ignoreCache: true,
      params: filters,
      abortKey: 'getIncomeStatement',
    });
  },
  getBankReconciliations(
    companyId: string,
    month: string,
    onlyReconciled = false,
  ): Promise<BankReconciliationResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/bankreconciliations`,
      ignoreCache: true,
      params: {
        month,
        onlyReconciled,
      },
    });
  },
  fetchBankReconciliation(
    companyId: string,
    bankAccountId: string,
    params: {
      month?: string;
      orderField?: string;
      order?: string;
      search?: string;
    } = {
      month: '',
      orderField: 'DATE',
      order: 'DESC',
    },
  ): Promise<BankReconciliationResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/bankreconciliations/${bankAccountId}`,
      ignoreCache: true,
      params,
    });
  },
  fetchBankReconciliationSuggestions(
    companyId: string,
    bankAccountId: string,
    params: {
      month?: string;
      sort?: string;
      lineIds?: string[];
      bankOperationIds?: string[];
      type?: Entity['entityType'];
    } = {
      month: '',
      sort: 'date',
      lineIds: [],
      bankOperationIds: [],
    },
  ): Promise<BankReconciliationResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/bankreconciliations/${bankAccountId}/suggestions`,
      ignoreCache: true,
      params,
    });
  },
  storePurchase(companyId: string, data: PurchaseSaveBody): Promise<PurchaseResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/purchases`,
      data,
    });
  },
  reconcilePurchase(companyId: string, data: PurchaseSaveReconcileBody): Promise<PurchaseResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/purchases/reconcile`,
      data,
    });
  },
  editPurchase(companyId: string, data: PurchaseSaveBody, purchaseSaleId: string): Promise<PurchaseResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/purchases/${purchaseSaleId}`,
      data,
    });
  },
  getPurchase(companyId: string, id: string): Promise<PurchaseResponse> {
    const date = +new Date();
    return api({
      method: 'get',
      url: `companies/${companyId}/purchases/${id}?time=${date}`,
    });
  },
  storeSale(companyId: string, data: IncomeSaveBody): Promise<IncomeResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/incomes`,
      data,
    });
  },
  reconcileSale(companyId: string, data: IncomeSaveReconcileBody): Promise<IncomeResponse> {
    return api({
      method: 'post',
      url: `companies/${companyId}/incomes/reconcile`,
      data,
    });
  },
  editSale(companyId: string, data: IncomeSaveBody, purchaseSaleId: string): Promise<IncomeResponse> {
    return api({
      method: 'put',
      url: `companies/${companyId}/incomes/${purchaseSaleId}`,
      data,
    });
  },
  getSale(companyId: string, id: string): Promise<IncomeResponse> {
    const date = +new Date();
    return api({
      method: 'get',
      url: `companies/${companyId}/incomes/${id}?time=${date}`,
    });
  },
  getPaychecks(companyId: string, id: string): Promise<PayslipResponse[]> {
    return api({
      method: 'get',
      ignoreCache: true,
      url: `companies/${companyId}/payslips/${id}`,
    });
  },
  getReconciliationSettings(companyId: string): Promise<BankReconciliationSettingsEquityResponse[]> {
    return api({
      method: 'get',
      url: `/companies/${companyId}/bank_reconciliation_settings/starting_equity`,
    });
  },
  saveReconciliationSettings(
    companyId: string,
    data: BankReconciliationSettingsEquityRequest,
  ): Promise<BankReconciliationSettingsEquityResponse> {
    return api({
      method: 'post',
      url: `/companies/${companyId}/bank_reconciliation_settings/starting_equity`,
      data,
    });
  },
  deleteReconciliationSettings(companyId: string, bankAccountId: string): Promise<void> {
    return api({
      method: 'delete',
      url: `/companies/${companyId}/bank_reconciliation_settings/starting_equity/${bankAccountId}`,
    });
  },
  getCompilationReport(
    companyId: string,
    params: CompilationReportFilters,
  ): Promise<GetEmployeeCompilationReportResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/compilation_report`,
      params,
      abortKey: 'getCompilationReport',
    });
  },
  sendCompilationReport(companyId: string, params: CompilationReportFilters, employeeIds: string[]): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/compilation_report/send`,
      params,
      data: { employeeIds },
    });
  },
  getExchangeRates(baseCurrency: string, quoteCurrency: string, date: string): Promise<any> {
    return api({
      method: 'get',
      url: 'exchange_rates',
      params: { baseCurrency, quoteCurrency, date },
      abortKey: 'getExchangeRates',
    });
  },
  getCurrencies(): Promise<CurrencyResponse[]> {
    return api({
      method: 'get',
      url: '/currencies',
    });
  },
};
