// Vuetify 2 Documentation https://v2.vuetifyjs.com

import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      // https://www.figma.com/design/IY6FM244DrXvlIiGy5c09B/Components-(master)?node-id=118-253&t=26Tp0qfvNsktCh9K-4
      light: {
        primary: colors.blue,
        secondary: {
          base: '#304156',
          darken1: '#242939',
        },
        success: {
          ...colors.green,
          base: '#4CAF50',
          lighten1: '#B4EDB6',
          lighten2: '#E2F8E3',
        },
        warning: {
          ...colors.orange,
          base: '#FFC107',
          lighten1: '#FFE186',
          lighten2: '#FFF0C2',
        },

        // System colors
        lightBlue: '#E3F3FE',
        background: '#F2F3F8',
        gray1: '#E0E0E0',
        gray2: '#9E9EA2',
        gray3: '#757575',
        darkBlue: '#304156',
        red: {
          ...colors.red,
          base: '#FF5252',
          lighten1: '#FF9C9C',
          lighten2: '#FFD6D6',
        },
        green: {
          ...colors.green,
          base: '#4CAF50',
          lighten1: '#B4EDB6',
          lighten2: '#E2F8E3',
        },
        orange: {
          ...colors.orange,
          base: '#FFC107',
          lighten1: '#FFE186',
          lighten2: '#FFF0C2',
        },
        info: {
          ...colors.blue,
          base: '#37a0f4',
          lighten2: '#e3f3fe',
        },

        //  Accent colors
        rainforest: '#007559',
        yellowGreen: '#9CB82E',
        carmine: '#CC0042',
        teal: '#2CC485',
        mango: '#FFC107',
        paradisePink: '#F24060',
        dynamite: '#FF471F',
        purple: '#5D3AEA',
        fightTheSunrise: '#FF99AC',
        orangeJuice: '#FF8000',
        lavender: '#A293EB',
        salmon: '#FA8072',
        persian: '#53C6BC',
      },
    },
  },
});
